@import "../../variables";

.footer {
  background-image: url("../../assets/images/footer-image.svg");
  background-size: cover;
  width: 100%;
  max-width: 1200px;
  border-radius: 30px;
  margin-top: 150px;

  @media screen and (max-width: 768px) {
    border-radius: 16px 16px 0 0;
    border: 1px solid #FFC3A9;
  }

  &__content {
    padding: 40px;

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        padding-bottom: 50px;
      }

      .footer-logo {
        object-fit: cover;
        width: 120px;
        height: 120px;
      }

      .subscribe-block {
        display: flex;
        gap: 16px;
        max-width: 536px;
        width: 100%;

        @media screen and (max-width: 480px) {
          flex-direction: column;
        }

        & > input {
          outline: none;
          color: var(--grey-grey-dark-1, #999);
          font-family: Grandis;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.36px;
          width: 100%;
          max-width: 400px;
          padding: 8px;
          border-radius: 8px;
          border: 1px solid var(--brown-brown-light, #FDF2ED);
          background: var(--white-white, #FEFCFA);
        }

        & > button {
          border: none;
          color: #FEFCFA;
          text-align: center;
          font-family: Grandis Extended;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.36px;
          cursor: pointer;
          border-radius: 24px;
          background: #F84F0E;
          transition: 0.2s;
          min-height: 38px;

          &:hover {
            background: white;
            color: #F84F0E;
          }
        }
      }
    }


    &-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        gap: 30px 0;
      }

      .company-action__links {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;

        .action-link {
          color: #15110F;
          font-family: "Grandis", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          cursor: pointer;

          &:hover {
            color: #F84F0E;
          }
        }
      }

      .contacts-and-social-block {
        @media screen and (max-width: 768px) {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
      }

      .footer-social-networks {
        margin-top: 40px;
      }

      .footer-social-networks___wrapper {
        display: flex;
        gap: 16px;
        .social-networks__link {
          & > svg {
            transition: 0.2s;
            border-radius: 50%;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
          }
        }
      }
      .instagram-annotation{
        max-width: 230px;
        font-family: 'Grandis Extended', serif;
        font-size: 14px;
      }
    }

    &-copyright-block {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      font-family: $additional-font;
      font-size: $fontSmall;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.32px;
      white-space: nowrap;
      flex-wrap: wrap-reverse;
      gap: 16px 10px;


      .company-name {
        color: #F84F0E;
      }

      .question-block {
        display: flex;
        gap: 20px 10px;
        flex-wrap: wrap;
        color: #FF2169;

        & a {
          color: rgb(248, 79, 14);
        }
      }

    }
  }
}
