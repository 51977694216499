@import "../../variables";

$spoiler-padding: 12px 20px;
$spoiler-font-size: 18px;
$spoiler-line-height: 24px;

.spoiler {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: $main-font;
  font-weight: 400;
  color: #333;
  padding: $spoiler-padding;
  transition: all 0.5s ease-in-out;

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;

    &__title {
      font-size: $fontRegular;
      line-height: 130%;
    }

    .open-icon {
      transform: rotate(180deg);
    }
  }

  &__content {
    color: #838383;
    font-size: $spoiler-font-size;
    line-height: $spoiler-line-height;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & ul, ol {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-enter {
      max-height: 0;
      overflow: hidden;
    }

    &-enter-active {
      max-height: 500px;
      transition: max-height 300ms ease-in;
    }

    &-exit {
      max-height: 0;
      display: none;
    }

    &-exit-active {
      max-height: 0;
      transition: none;
    }
  }
}


