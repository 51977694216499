@import '../../variables';

.start-with-us {
  width: 100%;
  max-width: 1554px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0 0 12px 12px;
  background: #F84F0E;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    max-width: 1400px !important;
    padding-top: 80px !important;
    padding-bottom: 80px !important;

    @media screen and (max-width: 1100px) {
      align-items: initial;
      flex-direction: column;
      padding-top: 40px !important;
      padding-bottom: 190px !important;
      position: relative;
      gap: 0;
    }

    &-left {
      max-width: 600px;
      @media screen and (max-width: 992px) {
        max-width: 350px;
      }
      @media screen and (max-width: 1100px) {
        max-width: initial;
      }

      .watch-btn {
        position: relative;
        cursor: pointer;
        border-radius: 30px;
        border: 1px solid #15110F;
        background: #FFF;
        color: #15110F;
        font-family: $additional-font;
        font-size: $fontSmall;
        letter-spacing: 0.32px;
        padding: 8px 16px 8px 45px;
        text-align: left;
        max-width: 365px;

        @media screen and (max-width: 992px) {
          font-size: 12px;
          padding-left: 30px;
          max-width: 280px;
        }
        @media screen and (max-width: 1100px) {
          max-width: initial;
        }

        &::after {
          position: absolute;
          left: 16px;
          top: calc(50% - 10px);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background-image: url('../../assets/icons/play-arrow-rounded.svg');
          background-size: cover;

          @media screen and (max-width: 992px) {
            left: 7px;
            top: calc(50% - 7px);
            width: 14px;
            height: 14px;
          }
        }
      }

      &-title {
        margin-top: 40px;
        margin-bottom: 32px;
        color: #FEFCFA;
        font-family: $additional-font;
        font-size: 48px;
        font-weight: 500;
        line-height: 120%;

        @media screen and (max-width: 1300px){
          font-size: 40px;
        }

        @media screen and (max-width: 992px) {
          font-size: 38px;
        }

        @media screen and (max-width: 1100px) {
          //font-size: 38px;
          margin-top: 32px;
          margin-bottom: 25px;
        }
        @media screen and (max-width: 480px) {
          font-size: 24px;
          //margin-top: 32px;
          margin-bottom: 12px;
          max-width: 100%;
        }
      }

      .become-sellers {
        @media screen and (max-width: 1100px) {
          position: absolute;
          bottom: 25px;
        }

        .become-sellers__wrapper {
          display: flex;
          gap: 4px;

          & > button {

            @media  screen and (max-width: 1100px){
              padding: 10px 40px;
            }
            &:hover {
              background: white;
              color: black;
            }
          }

          .phone-code__input {
            border-radius: 12px;
            border: 1px solid #D7D7D7;
            background: #FFF;
            width: 66px;
            height: 40px;
          }

          .phone-number__input {
            border-radius: 12px;
            border: 1px solid #D7D7D7;
            background: #FFF;
            width: 156px;
            height: 40px;
          }
        }

        &-description {
          margin-top: 12px;
          max-width: 426px;
          color: #FDF2ED;
          font-family: $main-font;
          font-size: 10px;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: 0.2px;

          @media screen and (max-width: 1100px) {
            max-width: 365px;
          }
        }

      }
    }

    &-right {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      gap: 20px;
      max-width: 50%;

      @media screen and (max-width: 1100px) {
        max-width: initial;
      }
    }
  }
}
