@import "../../variables";

.sellers-sale-info {
  padding-top: 80px;

  @media screen and (max-width: 480px) {
    padding-top: 42px;
  }

  &__content {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    //gap: 250px;

    @media screen and (max-width: 768px) {
      //flex-direction: column-reverse;
      gap: 20px;
    }

    &-title {
      width: 100%;
      font-size: 48px;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 22px;

      @media screen and (max-width: 992px) {
        font-size: 32px;
      }
      @media screen and (max-width: 480px) {
        font-size: 24px;
      }
    }

    .info-count {
      display: flex;
      //max-height: 130px;
      gap: 20px;

      @media screen and (max-width: 480px) {
        width: 100%;
        font-size: 12px;
        padding: 4px;
        min-width: 120px;
        justify-content: center;
      }

      &__item {
        border-radius: 28px;
        background: #FEFCFA;
        padding: 19px;
        width: 100%;
        min-width: 240px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media screen and (max-width: 992px) {
          min-width: 200px;
        }
        @media screen and (max-width: 768px) {
          padding: 12px;
          border-radius: 12px;
        }
        @media screen and (max-width: 480px) {
          font-size: 12px;
          //padding: 4px;
          min-width: 120px;

          padding: 20px;
          width: 80%;
        }

        &-title {
          width: 100%;
          display: block;
          text-align: center;
          color: #15110F;
          font-family: Grandis Extended;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media screen and (max-width: 480px) {
            font-size: 20px;
          }

        }

        &-count {
          color: #15110F;
          font-family: $additional-font;
          font-size: 40px;
          font-weight: 500;
          line-height: normal;

          @media screen and (max-width: 1100px) {
            font-size: 32px;
          }
          @media screen and (max-width: 992px) {
            font-size: 24px;
          }
          @media screen and (max-width: 480px) {
            font-size: 20px;
          }
        }

        &-description {
          color: #15110F;
          font-family: $additional-font;
          //font-size: $fontLarge;
          font-weight: 500;
          line-height: normal;
          font-size: 24px;
          //margin: 10px 0 0;
          white-space: nowrap;

          //@media screen and (max-width: 1100px) {
          //  //font-size: 22px;
          //}
          //
          //@media screen and (max-width: 992px) {
          //  font-size: 14px;
          //}
          @media screen and (max-width: 480px) {
            font-size: 20px;
          }
        }
      }
    }

    &__title {
      max-width: 403px;
      text-align: right;
      color: #15110F;
      font-family: $additional-font;
      font-size: 40px;
      font-weight: 500;
      line-height: 120%;
      margin: 0;

      @media screen and (max-width: 1100px) {
        font-size: 32px;
      }

      @media screen and (max-width: 992px) {
        font-size: 22px;
        max-width: 240px;
      }
      @media screen and (max-width: 768px) {
        max-width: initial;
        order: 2;
      }
      @media screen and (max-width: 480px) {
        text-align: center;
      }
      @media screen and (max-width: 400px) {
        max-width: 240px;
      }

      .red-manuscript__wrapper {
        position: relative;
        display: inline-block;

        &::after {
          position: absolute;
          left: -4px;
          top: -3px;
          content: "";
          display: block;
          width: 200px;
          height: 56px;
          background-image: url('../../assets/images/red-manuscript.svg');
          background-size: cover;

          @media screen and (max-width: 1100px) {
            width: 158px;
            height: 45px;
            left: -4px;
            top: -3px;
          }

          @media screen and (max-width: 992px) {
            width: 110px;
            height: 31px;
            left: -4px;
            top: -1px;
          }
        }
      }

    }
  }

  .register-your-shop {
    padding-top: 50px;
    width: 100%;

    @media screen and (max-width: 768px) {
      order: 1;
      padding-top: 20px;
    }

    &__title {
      color: #15110F;
      font-family: Grandis Extended;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 16px;

      & > strong {
        background: #F84F0E;
        color: white;
        padding: 4px;
        border-radius: 12px;
      }

      @media screen and (max-width: 992px) {
        font-size: 24px;
      }
    }
  }
}
