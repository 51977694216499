@import "../../variables";

$header-padding: 20px 40px;
$header-bg-color: #FFC3A9;
$header-text-color: #15110F;

.header {
  width: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: $header-padding;
    max-width: 1544px;
    margin: 0 auto;
    padding-top: 60px;

    @media screen and (max-width: 768px) {
      padding-top: 30px;
    }
    @media screen and (max-width: 480px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }


  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;

      &-icon {
        width: 120px;
        height: auto;
        margin-left: 10px;
      }

      &-title {
        color: #F84F0E;
        font-family: $additional-font;
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
      }
    }

    &__social-networks {
      display: flex;
      gap: 12px;
      margin-left: auto;
      padding-right: 120px;

      @media screen and (max-width: 768px) {
        padding-right: 40px;
      }
      @media screen and (max-width: 550px) {
        padding-right: 10px;
      }


      @media screen and (max-width: 480px) {
        display: none;
      }

      .social-networks__link {
        display: inline-block;
        border-radius: 50%;
        transition: 0.2s;

        & > svg {
          border-radius: 50%;
          transition: 0.2s;
          width: 34px;
          height: 34px;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
          }
        }
      }
    }

    &__btn {
      color: #15110F;
      font-size: 18px;
      font-weight: 500;
      font-family: Grandis Extended;
      line-height: 140%;
      border-radius: 24px;
      background: var(--brand-300, #FFC3A9);
      padding: 8px 16px;
      border: none;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      @media screen and (max-width: 576px) {
        display: none;
      }


      &:hover {
        color: white;
        background: #F84F0E;
      }
    }

    &__learn-btn {
      border-radius: 24px;
      background: $header-bg-color;
      cursor: pointer;
      border: 0;
      padding: 8px 16px;
      color: $header-text-color;
      font-family: $additional-font;
      font-size: $fontRegular;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      transition: 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-bottom {
    padding: $header-padding;
    border-radius: 20px;
    background: $header-bg-color;

    @media screen and (max-width: 992px ){
      padding: 20px 24px;
    }

    @media screen and (max-width: 480px) {
      background: none;
      padding: 0;
    }

    &__desktop {
      display: flex;
      gap: 45px;

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    .burger__btn {
      display: none;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      border: none;
      border-radius: 8px;
      background: #F84F0E;
      cursor: pointer;
      transition: opacity 0.15s ease-in-out;

      @media screen and (max-width: 992px) {
        display: flex;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &__link {
      color: $header-text-color;
      font-family: $main-font;
      font-size: $fontRegular;
      line-height: 140%;
      transition: 0.2s;
      margin: -5px -1rem -4px;
      padding: 5px 1rem 4px;
      background: #FDF2ED;
      border-radius: 2px 30px;

      &:hover {
        color: #F84F0E;
      }
    }
  }
}

.language-selector__desktop {
  @media screen and (max-width: 480px) {
    display: none;
  }
}

