$gap: 24px;
$padding-top: 48px;
$image-row-gap: 20px;

.images-rows {
  display: flex;
  flex-direction: column;
  gap: $gap;
  padding-top: $padding-top;
  overflow: hidden;
  width: 100%;
  align-items: center;

  .image-row {
    display: flex;
    gap: $image-row-gap;
    border-radius: 12px;

    & > img {
      @media screen and (max-width: 768px) {
        max-width: 300px;
        max-height: 150px;
      }
      @media screen and (max-width: 480px) {
        max-width: 200px;
        max-height: 100px;
      }
    }
  }
}
