@import "../../variables";

.custom-button {
  width: 100%;
  max-width: 430px;
  border: none;
  padding: 10px 0;
  border-radius: 8px;
  background: #15110F;
  color: #FEFCFA;
  text-align: center;
  font-family: $additional-font;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  transition: 0.1s ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
    border-radius: 12px;
  }

  &:hover {
    color: white;
    background: #F84F0E;
  }

  &:active {
    color: #15110F;
    background: #FEFCFA;
  }
}
