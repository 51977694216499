.contact-us-whatsapp {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-top: 25px;

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    //color: var(--white-white, #FEFCFA);
    font-family: Grandis Extended;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
  }

  &__btn {
    color: var(--black-black, #15110F);
    text-align: center;
    font-family: Grandis Extended;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    border-radius: 8px;
    background: var(--brown-brown-light, #FDF2ED);
    border: none;
    max-width: 260px;
    padding: 10px;
    cursor: pointer;

    transition: 0.2s;

    &:hover {
      color: #FDF2ED;
      background: #15110F;
    }
  }
}

.primary-text {
  color: var(--white-white, #FEFCFA);

}

.second-text {
  color: var(--black-black, #15110F);
}

.primary-btn {

}

.second-btn {
  border-radius: 8px;
  border: 1px solid var(--brand-900, #F84F0E);
  &:hover{
    background: #F84F0E;
    color: #FFFFFF;
  }
}
