@import "../../variables";

$padding-top: 180px;
$wrapper-padding-top: 70px;
$left-max-width: 395px;
$button-max-width: 395px;

.how-to-start-selling {
  padding-top: $padding-top;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 60px;
  }

  &__content {
    &-title {
      margin: 0 0 16px;
    }

    &-description {
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: $wrapper-padding-top;
      gap: 40px;

      @media screen and (max-width: 992px) {
        padding-top: 45px;
      }

      &-left {
        max-width: $left-max-width;
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media screen and (max-width: 768px) {
          max-width: initial;
        }

        & > button {
          max-width: $button-max-width;
        }
      }

      &-right {
        @media screen and (max-width: 992px) {
          display: none;
        }

        & > img {
          width: 100%;
        }
      }
    }
  }
}
