@import '~normalize.css';
@import 'styles/fonts.scss';
@import "variables";

#root {
  background-color: $backgroundColor;
  width: 100%;
  //height: 100vh;

}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: inherit;
  text-decoration: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

section {
  max-width: 1544px;
  width: 100%;
  display: flex;
  justify-content: center;
}

h2 {
  font-size: 32px;
  color: #333;
  line-height: 120%;
  font-weight: 500;
  font-family: $additional-font;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
}

p {
  color: #838383;
  font-family: $main-font;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 140%;
  }
}