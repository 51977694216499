$fontSmall: 16px;
$fontRegular: 18px;
$fontLarge: 24px;
$fontExtraLarge: 32px;
$fontHuge: 60px;

$main-font: 'Grandis', sans-serif;
$additional-font: 'Grandis Extended';
$main-font-size: 18px;

$backgroundColor: #FDF2ED;
//$whiteColor: #FEFCFA;
