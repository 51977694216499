@import "../../variables";

.partner-program {

  &__content {
    padding-top: 180px !important;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media screen and (max-width: 768px) {
      padding-top: 100px !important;
    }

    &-left {
      width: 50%;

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      .content__title {

      }

      .content__description {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 16px 0;
        max-width: 385px;

        @media screen and (max-width: 992px) {
          max-width: 70%;
        }
        @media screen and (max-width: 576px) {
          max-width: 100%;
        }
      }

      .content__subdescription {
        color: #F84F0E;
        font-family: $main-font;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.36px;
        max-width: 330px;
        padding: 8px 14px;
        border-radius: 12px;
        background: #FFF;
      }

      & > button {
        margin-top: 32px;
      }
    }

    &-right {
      width: 50%;

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

  }

}
