@import "../../variables";

.work-with-us {
  padding-top: 100px;
  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 60px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 77px;

    @media screen and (max-width: 1100px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 40px;
    }

    &-left {
      max-width: 485px;

      @media screen and (max-width: 1100px) {
        max-width: 490px;
      }

      &-title {
        color: #15110F;
        font-family: Grandis Extended;
        font-size: 40px;
        font-weight: 500;
        line-height: 100%;

        @media screen and (max-width: 768px){
          font-size: 36px;
        }
        @media screen and (max-width: 480px){
          font-size: 24px;
        }
      }

      .work-with-us__list {
        display: flex;
        flex-direction: column;
        gap: 44px;
        padding-top: 40px;

        &-item {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 12px 0;

          &__title {
            color: #15110F;
            font-family: Grandis Extended;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 4px 10px;
            border-radius: 12px;
            background: #FFC3A9;
            width: max-content;

            @media screen and (max-width: 480px){
              width: 100%;
              text-align: center;
            }
          }

          &__description {

          }
          .sub-description {
            padding-top: 8px;
            padding-bottom: 20px;
          }

        }
      }

      &-description {
        margin: 16px 0 24px;
      }

      & > button {
        max-width: 331px;
      }
    }

    &-right {
      display: flex;
      align-items: flex-start;
      gap: 17px;
      height: 100%;

      @media screen and (max-width: 1100px) {
        width: 100%;
      }

      & > img {
        width: calc(100% / 2 - 11px);

        @media screen and (max-width: 1300px) {
          width: calc(100% / 3 - 11px);
        }
      }
    }
  }
}
