@import "../../variables";

.join-sellers {
  padding-top: 180px;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 60px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-title {
      font-size: 40px;

      @media screen and (max-width: 992px) {
        font-size: 24px;
      }
      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      //flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
      }

      &-description {
        max-width: 533px;
      }
    }

    .preview-catalog {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
        gap: 20px;
      }

      .link-to-all-categories {
        width: max-content;
        margin-left: auto;
        cursor: pointer;
        display: block;
        border-radius: 24px;
        background: #F3F2F1;
        color: #15110F;
        padding: 10px 16px 8px 16px;
        font-family: Grandis Extended;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.28px;
        margin-bottom: 32px;
        margin-top: 32px;
        transition: 0.2s;

        @media screen and (max-width: 480px) {
          margin: 0 auto;
        }

        &:hover {
          color: white;
          background: #F84F0E;
        }
      }

      .preview-catalog__wrapper {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 992px) {
          flex-direction: column;
        }

        .catalog-image-1, .catalog-image-2, .catalog-image-3, .catalog-image-4, .catalog-image-5 {
          display: block;
          position: relative;
          cursor: pointer;
          transition: 0.2s;

          @media screen and (max-width: 480px) {

            width: 100%;
            & > img {
              width: 100%;
              max-height: 173px;
            }
          }

          &:hover {
            transform: scale(1.05);

            & > img {
              box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

            }
          }
        }

        .catalog-image-1, .catalog-image-2, .catalog-image-4, .catalog-image-5 {
          @media screen and (max-width: 992px) {
            width: 50%;
          }
          @media screen and (max-width: 480px) {
            width: 100%;
          }
        }

        .block-title {
          color: #FEFCFA;
          font-family: Grandis Extended;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          position: absolute;
          top: 0;
          padding: 20px;
        }

        .block-button {
          position: absolute;
          bottom: 20px;
          right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          border-radius: 50%;
          border: none;
        }

        .catalog-images-left {
          max-width: 380px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media screen and (max-width: 992px) {
            flex-direction: row;
            width: 100%;
            max-width: initial;
            max-height: 240px;
          }
          @media screen and (max-width: 480px) {
            flex-direction: column;
            width: 100%;
            max-height: initial;

          }

          .catalog-image-1 {

            @media screen and (max-width: 992px) {
              max-height: 240px;
            }

            & > img {
              width: 100%;
              height: 260px;
              object-fit: cover;
              border-radius: 20px;
              @media screen and (max-width: 992px) {
                height: 100%;
              }

            }
          }

          .catalog-image-2 {
            @media screen and (max-width: 992px) {
              max-height: 240px;
            }

            & > img {
              width: 100%;
              height: 340px;
              object-fit: cover;
              border-radius: 20px;
              @media screen and (max-width: 992px) {
                height: 100%;
              }
            }
          }
        }

        .catalog-images-middle {
          max-width: 380px;
          @media screen and (max-width: 992px) {
            max-width: 100%;

          }

          .catalog-image-3 {
            @media screen and (max-width: 992px) {
              max-height: 240px;
            }

            & > img {
              width: 100%;
              height: 620px;
              object-fit: cover;
              border-radius: 20px;
              @media screen and (max-width: 992px) {
                height: 240px;
              }
            }
          }
        }

        .catalog-images-right {
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-width: 380px;
          @media screen and (max-width: 992px) {
            flex-direction: row;
            width: 100%;
            max-width: initial;
            max-height: 240px;
          }
          @media screen and (max-width: 480px) {
            flex-direction: column;
            width: 100%;
            max-height: initial;

          }


          .catalog-image-4 {
            @media screen and (max-width: 992px) {
              max-height: 240px;
            }

            & > img {
              width: 100%;
              height: 340px;
              object-fit: cover;
              border-radius: 20px;
              @media screen and (max-width: 992px) {
                height: 100%;
              }
            }
          }

          .catalog-image-5 {
            @media screen and (max-width: 480px) {
              display: none;
            }

            & > img {
              width: 100%;
              height: 260px;
              object-fit: cover;
              border-radius: 20px;

              @media screen and (max-width: 992px) {
                max-height: 240px;
              }

            }
          }

        }
      }
    }

  }
}
