.data-modal {
  position: fixed;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  //#AmoContainer {
  //  width: 100%;
  //
  //  min-width: 509px;
  //  & > iframe {
  //    height: 460px !important;
  //  }
  //
  //  @media (max-width: 768px) {
  //    min-width: 460px;
  //  }
  //
  //  @media (max-width: 480px) {
  //    min-width: 320px;
  //  }
  //}

  .error {
    border: 1px solid red;
  }

  .error-text {
    color: red;
    font-family: Grandis Extended;
    font-size: 14px;
  }

  &__content {
    position: relative;
    max-width: 510px;
    border-radius: 20px;
    background: #FDF2ED;
    padding: 24px;
    margin: 5px;

    box-shadow: 2px 2px 2px 0 rgba(141, 141, 143, 0.30);

    .data-modal__close-btn {
      position: absolute;
      top: 10px;
      right: 20px;
      margin-left: auto;
      //margin-bottom: 24px;
      width: 14px;
      height: 14px;
      opacity: 0.7;
      cursor: pointer;
      transition: opacity ease 0.4s;

      &:hover {
        opacity: 1;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 10px;
        display: block;
        width: 16px;
        height: 2px;
        background: #15110F;
        border-radius: 2px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }


    &-title {
      color: #15110F;
      font-family: Grandis Extended;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 24px;
      margin-top: 45px;
    }

    &-description {
      // color: #15110F;
      // font-family: Grandis;
      // font-size: 16px;
      // font-style: normal;
      // font-weight: 400;
      // line-height: 140%;
      // margin-bottom: 8px;
      margin-top: 12px;
      font-size: 14px;
      color: #000;

      .technical-support {
        color: #F84F0E;
        cursor: pointer;
      }

    }

    .fields-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    input {
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #CABCB5;
      background: #FEFCFA;
      color: #15110F;
      font-family: Grandis;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
    }
  }

  .form-control {
    width: 100% !important;
    font-size: 18px !important;
    height: auto !important;
    background: #FEFCFA !important;
    border-radius: 8px !important;
  }

  .submit__btn {
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    cursor: pointer;
    border-radius: 24px;
    background: #F84F0E;
    color: #FEFCFA;
    font-family: Grandis Extended;
    border: none;
    margin-top: 16px;
  }

  .submit__btn.disabled {
    border: 1px solid #FFC3A9;
    background: #FDF2ED;
    color: #FFC3A9;
    cursor: default;
  }


  .accept-rules {
    color: #15110F;
    font-family: Grandis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 24px;

    display: flex;
    gap: 8px;
    align-items: flex-start;

    & > input {
      width: auto;
      min-width: 20px;
      height: 20px;
      margin-top: 5px;
    }

    a {
      color: #F84F0E;
      cursor: pointer;
      margin: 0 4px;
    }

    span {
      cursor: pointer;
      user-select: none;
    }
  }
}
