.failure-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #00000063;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &__content {
    position: relative;
    max-width: 510px;
    border-radius: 20px;
    background: #FDF2ED;
    box-shadow: 2px 2px 2px 0px rgba(141, 141, 143, 0.30);
    padding: 68px 20px 20px;
    color: #15110F;
    line-height: normal;
    font-style: normal;


    .failure-modal__close-btn {
      position: absolute;
      top: 10px;
      right: 20px;
      margin-left: auto;
      //margin-bottom: 24px;
      width: 14px;
      height: 14px;
      opacity: 0.7;
      cursor: pointer;
      transition: opacity ease 0.4s;

      &:hover {
        opacity: 1;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 10px;
        display: block;
        width: 16px;
        height: 2px;
        background: #15110F;
        border-radius: 2px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    &-title {
      font-family: Grandis Extended;
      font-size: 24px;
      font-weight: 500;
    }

    &-description {
      font-family: Grandis;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      margin-top: 16px;
      margin-bottom: 24px;
    }

    & > button, & > a {
      border-radius: 24px;
      background: #F84F0E;
      width: 100%;
      border: 0;
      padding: 10px;
      color: #FEFCFA;
      text-align: center;
      font-family: Grandis Extended;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      cursor: pointer;
      display: inline-block;
    }
  }
}

