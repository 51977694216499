.burger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
}

.burger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 20px;
  margin: 20px;
  cursor: pointer;
}

.burger-icon span {
  width: 100%;
  height: 4px;
  background-color: #333;
  border-radius: 2px;
  transition: transform 0.3s, opacity 0.3s;
}

.burger-icon.open span:nth-child(2) {
  opacity: 0;
}

.burger-icon.open span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.burger-icon.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.menu-items {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menu-items a {
  cursor: pointer;
}