@import "../../variables";

$benefits-for-sellers-padding-top: 180px;
$benefits-for-sellers-padding-bottom: 40px;

$benefits-item-width: 25%;
$benefits-item-height: 340px;
$benefits-item-border-radius: 0 175px 175px 0;

$benefits-item-1-background: #FDF2ED;
$benefits-item-2-background: #FFC3A9;

@mixin benefits-item__block($background, $left, $z-index) {
  background: $background;
  left: $left;
  z-index: $z-index;
  height: $benefits-item-height;
  width: $benefits-item-width;
  border-radius: $benefits-item-border-radius;
  position: relative;

  @media screen and (max-width: 1100px) {
    width: 50%;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    justify-content: center;
  }
}

.benefits-for-sellers {
  padding-top: $benefits-for-sellers-padding-top;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 60px;
  }

  &__content {

    @media screen and (max-width: 768px) {
      position: relative;
    }

    &-header {
      display: flex;
      padding-bottom: $benefits-for-sellers-padding-bottom;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;

      &-title {
      }

      & > button {
      }
    }

    &-items {
      overflow: hidden;
      position: relative;
      display: flex;
      height: 250px;
      align-items: center;

      @media screen and (max-width: 1100px) {
        height: auto;
        flex-wrap: wrap;
        gap: 10px 0;
      }

      .benefits-item__block-1 {
        @include benefits-item__block($benefits-item-1-background, 105px, 4);

        @media screen and (max-width: 1100px) {
          @include benefits-item__block($benefits-item-1-background, 25px, 4)
        }
        @media screen and (max-width: 576px) {
          @include benefits-item__block($benefits-item-1-background, 0, 4)
        }
      }

      .benefits-item__block-2 {
        @include benefits-item__block($benefits-item-2-background, 55px, 3);

        @media screen and (max-width: 1100px) {
          @include benefits-item__block($benefits-item-2-background, -62px, 3)
        }
        @media screen and (max-width: 576px) {
          @include benefits-item__block($benefits-item-2-background, 0, 3)
        }
      }

      .benefits-item__block-3 {
        @include benefits-item__block($benefits-item-1-background, 33px, 2);
        @media screen and (max-width: 1100px) {
          order: 4;
          @include benefits-item__block($benefits-item-1-background, -37px, 1);
        }
        @media screen and (max-width: 576px) {
          order: 3;
          @include benefits-item__block($benefits-item-1-background, 0, 1);
        }
      }

      .benefits-item__block-4 {
        @include benefits-item__block($benefits-item-2-background, -22px, 1);

        & > .benefits-item {
          right: 15px;
        }

        @media screen and (max-width: 1100px) {
          order: 3;
          @include benefits-item__block($benefits-item-2-background, 25px, 2);
        }
        @media screen and (max-width: 576px) {
          order: 4;
          @include benefits-item__block($benefits-item-2-background, 0, 2);
        }
      }
    }

    &-annotation {
      padding-top: 40px;

      @media screen and (max-width: 768px) {
        padding-top: 20px;
      }
    }
  }
}
