@import "variables";

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: 1228px;
  width: 100%;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}