@font-face {
  font-display: swap;
  font-family: Grandis;
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Grandis-Light.ttf');
}

@font-face {
  font-display: swap;
  font-family: Grandis;
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Grandis-Regular.ttf");
}

@font-face {
  font-display: swap;
  font-family: Grandis;
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Grandis-Medium.ttf");
}

@font-face {
  font-display: swap;
  font-family: Grandis;
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Grandis-Bold.ttf");
}

@font-face {
  font-display: swap;
  font-family: Grandis;
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/Grandis-Black.ttf");
}

//extended
@font-face {
  font-display: swap;
  font-family: "Grandis Extended";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/GrandisExtended-Regular.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Grandis Extended";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/GrandisExtended-Medium.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Grandis Extended";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/GrandisExtended-Bold.ttf");
}
