@import "../../variables";

.quick-registration {
  padding-top: 180px;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 60px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 63px;

    @media screen and (max-width: 768px) {
      gap: 10px;
      justify-content: space-between;
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      gap: 48px;
    }

    &-left {

      & > img {
        width: 100%;

      }
    }

    &-right {
      &-title {
      }

      &-description {
        color: #838383;
        font-family: $main-font;
        font-size: $fontRegular;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin: 16px 0;
        max-width: 400px;
      }
    }
  }
}
