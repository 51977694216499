@import "../../variables";

.we-are-ready-to-help {
  max-width: 1310px;
  padding-top: 180px;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 480px) {
    padding-top: 60px;
  }

  &__content {
    &-head {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px 0;

      &-title {
      }

      .link-to-faq {
        display: block;
        color: white;
        max-width: 382px;
        width: 100%;
        border-radius: 8px;
        background: #15110F;
        padding: 10px 16px 8px 16px;
        text-align: center;
        font-family: Grandis Extended;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.36px;
        transition: 0.2s;
        max-height: 36px;

        &:hover {
          background: #F84F0E;
        }
      }

      &-description {
        margin: 16px 0 48px;
      }
    }


    &-wrapper {
      display: flex;
      gap: 20px;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }

      &-left {
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 396px;

        @media screen and (max-width: 992px) {
          width: 100%;
          flex-direction: row;
          //max-height: 140px;
          //overflow: hidden;
          max-width: initial;
        }
        @media screen and (max-width: 650px) {
          flex-direction: column;
          overflow: initial;
          max-height: initial;
        }

        & > img {
          object-fit: cover;
          @media screen and (max-width: 992px) {
            width: 50%;
            height: 100%;
            max-height: 140px;
          }
          @media screen and (max-width: 650px) {
            width: 100%;
            max-height: 130px;
            object-fit: cover;
          }
        }

        .help-block {
          border-radius: 12px;
          background: #F84F0E;
          padding: 16px 24px;
          font-family: $main-font;

          @media screen and (max-width: 992px) {
            width: 50%;
          }
          @media screen and (max-width: 650px) {
            width: auto;
          }

          &__title {
            color: #FEFCFA;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.48px;
            margin: 0;
          }

          &__description {
            color: #F1F0EF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 4px;
            margin-bottom: 17px;
          }

          &__btn {
            padding: 10px 0;
            width: 100%;
            border: none;
            background: #FEFCFA;
            border-radius: 8px;
            color: #15110F;
            font-family: $additional-font;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.28px;
            cursor: pointer;
            transition: 0.2s;

            &:active,&:hover {
              color: #FEFCFA;
              background: #15110F;
            }
          }
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        max-width: 812px;

        & > h5 {
          color: #333;
          font-family: $main-font;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: 0.36px;
          margin: 0;
        }

        .spoilers-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    }
  }
}
