@import "../../../variables";

$number-width: 80px;
$number-height: 80px;
$number-bg-color: #F84F0E;
$number-color: #FEFCFA;
$title-color: #333;
$description-color: #838383;

.how-to-start-selling__item {
  display: flex;
  align-items: center;
  gap: 24px;

  &-number {
    min-width: $number-width;
    height: $number-height;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: $number-bg-color;
    color: $number-color;
    font-family: $additional-font;
    font-size: $fontLarge;
    font-weight: 500;
    line-height: normal;

    @media screen and (max-width: 768px) {
      min-width: 64px;
      height: 64px;
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__title {
      margin: 0;
      color: $title-color;
      font-family: $additional-font;
      font-size: $fontRegular;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.36px;

    }

    &__description {
      margin: 0;
      color: $description-color;
      font-family: $main-font;
      font-size: $fontSmall;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}
