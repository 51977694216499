.selector {
  display: flex;
  gap: 20px;

  &__btn {
    border-radius: 8px;
    border: none;
    padding: 8px;
    background: #FFC3A9;
    color: #15110F;
    font-family: Grandis Extended;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
  }
}

.active {
  border: 1px solid;
}