@import "../../../variables";

.footer__item {
  //width: 25%;
  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  &-title {
    margin-bottom: 24px;
    color: #15110F;
    font-family: $additional-font;
    font-size: 24px;
    font-weight: 500;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.footer-item__link {
  color: #15110F;
  font-family: $main-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;

  &:hover{
    color: #F84F0E;
  }
}
