@import "../../../variables";

$benefits-item-top: 80px;
$benefits-item-right: 45px;
$benefits-item-gap: 20px;

$benefits-item-font-size: 32px;
$benefits-item-description-max-width: 180px;
$benefits-item-color: #15110F;

.benefits-item {
  position: absolute;
  top: $benefits-item-top;
  right: $benefits-item-right;
  display: flex;
  flex-direction: column;
  gap: $benefits-item-gap;
  color: #15110F;
  font-weight: 500;

  @media screen and (max-width: 1100px) {
    position: initial;
    margin-right: 100px;
  }
  @media screen and (max-width: 768px) {
    margin-right: 40px;
  }
  @media screen and (max-width: 620px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 576px) {
    margin-right: 0;
  }

  &__icon {
    width: 40px;
    height: 40px;
  }

  &__title, &__description {
    font-family: $additional-font;
    color: $benefits-item-color;
    font-weight: 500;
  }

  &__title {
    font-size: $benefits-item-font-size;
    line-height: 120%;
  }

  &__description {
    font-size: 16px;
    line-height: 130%;
    max-width: $benefits-item-description-max-width;

  }
}
